// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start();

import 'bootstrap';
import 'jquery.easing';
import 'slick-carousel';
import '../src/scroll-nav';
import '../src/jquery.checkall';
import '../src/helpers';
import '../src/professional_group';
import '../src/company';
import '../src/popover_preview';
import '../src/carousel';
import '../src/ping_presence';
import hcSticky from 'hc-sticky';
import ahoy from "ahoy.js";
import findCurrentAppointment from '../src/current_appointment';
import isMobile from '../src/isMobile';
import { summitCountDown } from "../src/countdown";
import carouselSettings from '../src/sponsor_carousels';
import checkNotice from '../src/check_notice';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

window.$ = $;
window.jQuery = jQuery;
window.isMobile = isMobile;
window.carouselSettings = carouselSettings;
// stylesheets
import '../src/font-awesome.js';

const toggleDown = '<i class="fas fa-arrow-down"></i>';
const toggleUp = '<i class="fas fa-arrow-up"></i>';

const toggleArrow = elem => $(elem).html($('#description').is(':visible') ? toggleUp : toggleDown);

$(document).ready(() => {
  $('#toggle_description').click(event => {
    event.preventDefault();
    $('#description').slideToggle({
      easing: 'easeInExpo',
      duration: 1000,
      step: () => toggleSponsorBackground(),
      done: () => toggleArrow(event.currentTarget),
    });
  });
  findCurrentAppointment();
  setInterval(findCurrentAppointment, 60000);
  if (window.screen.width > 776) {
    $('#agenda .buttons .btn').addClass('btn-lg');
  }

  $('.checkall').checkall();

  if ($('#congress-days-buttons .congress-day-links').length) {
    $('#congress-days-buttons .congress-day-links').click(function (event) {
      event.preventDefault();
      const currentDay = $(this).data('congress-day');

      // toggle sponsors
      $('#all-sponsors .sponsors-by-day').fadeOut('1000', 'easeInOutExpo').hide();
      $('#partners-carousel .sponsors-by-day').fadeOut('1000', 'easeInOutExpo').hide();
      $(`#sponsors-${currentDay}`).fadeIn('1000', 'easeInOutExpo').show();
      $(`#sponsors-carousel-${currentDay}`).fadeIn('1000', 'easeInOutExpo').show();
      //
      //toggle days buttons
      $('#congress-days-buttons .congress-day-links.btn-primary').toggleClass('btn-primary').toggleClass('btn-outline-primary');
      $(`#congress_day_link-${currentDay}`).toggleClass('btn-primary').toggleClass('btn-outline-primary');
      //
      // //toggle agenda
      $('.agenda--wrapper .appointments-by-day').fadeOut('1000', 'easeInOutExpo').hide();
      $(`#appointments-${currentDay}`).fadeIn('1000', 'easeInOutExpo').show();
    })
  }

  if ($('#countdown').length) {
    summitCountDown()
  }

  if ($('.hcsticky').length && !isMobile()) {
    const contactForm = new hcSticky('.sponsor--contact-form', {
      stickTo: '.wrapper',
      top: 130,
    });
    const sidePromo = new hcSticky('aside.promotion', {
      stickTo: '#main-content',
      top: 130,
    });
  }

  if ($('#notice-container').length) {
    setInterval(checkNotice, 60000);
  }
});


$(document).on('ajax:send', '#new_congresses_sponsors_message', function(e) {
  Helpers.appendIndicator($(e.target));
});

$(document).on('ajax:success', '#new_congresses_sponsors_message', function(e) {
  var data = event.detail[0];
  $('#sponsor_message').html(data['html'])
});

$(document).on('ajax:error', '#new_congresses_sponsors_message', function(e) {
  Helpers.removeIndicator($(e.target))
});

$(document).on('ajax:send', '#congress_sponsor_send_attachments', function(e) {
  $(e.target).prop('disabled', true);
});

$(document).on('ajax:success', '#congress_sponsor_send_attachments', function(e) {
  var data = event.detail[0];
  $(e.target).prop('disabled', false);
  alert(data['result'])
});


$(document).on('click', 'a.sponsor-external-link', function(e) {
  var track_data = $(e.target).data('track').split('/')
  ahoy.track('sponsor_link_click', {
    link_id: parseInt(track_data[0]),
    sponsor_id: parseInt(track_data[1]),
    congress_slug: track_data[2],
    section_slug: track_data[3]});
});

$(document).on('click', 'a.sponsor-product-link', function(e) {
  var track_data = $(e.target).closest('a').data('track').split('/')
  ahoy.track('sponsor_product_click', {
    product_id: parseInt(track_data[0]),
    sponsor_id: parseInt(track_data[1]),
    congress_slug: track_data[2],
    section_slug: track_data[3],
    on_marketplace: (track_data[4] != undefined)
  });
});

const toggleSponsorBackground = () =>
  $('#description-bg').css('height', $('#sponsor-description').outerHeight())

const images = require.context('../images', true)
