$(document).ready(() => {
  if ($('#congress').length) {
    const presence_path = $('#congress').data('ping');
    if (presence_path.length > 0) {
      $.ajax({type: 'POST', url: presence_path})
      setInterval(
        () => {
          $.ajax({type: 'POST', url: presence_path})
        }, 60000)
    }
  }
});
